@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    mat-table.mat-mdc-table.mdc-data-table__table {
        border-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);

        .mat-mdc-header-row {
            background: mat.m2-get-color-from-palette($primary-palette, 500);

            .mat-mdc-header-cell {
                color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            }

            .mat-sort-header-arrow {
                color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            }
        }

        .mat-mdc-row {
            background: mat.m2-get-color-from-palette($primary-palette, 100);

            &:nth-child(even) {
                background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            }

            &.highlighted {
                background: mat.m2-get-color-from-palette($primary-palette, greenPale);
            }

            &.highlighted:hover,
            &:hover {
                background: mat.m2-get-color-from-palette($primary-palette, orangeCustom, 0.2);
            }

            .mat-mdc-cell {
                color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
            }

            .link {
                color: mat.m2-get-color-from-palette($primary-palette, 500);
            }
        }

        .mat-mdc-cell {
            border-color: mat.m2-get-color-from-palette($primary-palette, greyCustom, 0.5);
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .mat-mdc-header-cell {
        @include mat.m2-typography-level($config, 'headline-4');
    }
}
