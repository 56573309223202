// Border
$border-default: 1px solid #ddd;

// fonts
$base-font-size: 1.6rem;
$font-family-regular: 'Montserrat Regular', sans-serif;
$font-family-semi-bold: 'Montserrat SemiBold', sans-serif;
$font-family-regular-italic: 'Montserrat Regular Italic', sans-serif;
$font-family-medium: 'Montserrat Medium', sans-serif;
$font-family-icomoon: 'icomoon';

// colors
$blue: #2c6c93;
$blue-dark: #0a354f;
$blue-light: #d3e2ef;
$blue-bright: #2278cf;
$blue-dim: #95b5c8;
$white: #fff;
$white-smoke: #f3f3f3;
$black: #000;
$black-average: #aaa;
$grey: #767676;
$grey-light: #dedede;
$grey-lightest: #efefef;
$grey-lightest-2: #f3f3f3;
$grey-average: #808080;
$orange: #ff9700;
$orange-light: #ffbb2d;
$red-light: #fb5d5d;
$red-pale: #ff8f8f;
$red: #c32f2f;
$green: #24c44c;
$yellow: #e6cf00;
$purple: #9747ff;
$green-pale: #c7d9be;
$blue-zinc: #b6c3cb;

$sm-breakpoint: 1023px;
$md-breakpoint: 1024px;
$lg-breakpoint: 1500px;
$xl-breakpoint: 1599px;
$xxl-breakpoint: 1600px;
$xxxl-breakpoint: 1750px;