@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    mat-snack-bar-container.mat-mdc-snack-bar-container {
        background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
        color: mat.m2-get-color-from-palette($primary-palette, blackCustom);

        p {
            color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
        }

        &.info {
            border-color: mat.m2-get-color-from-palette($primary-palette, 500);

            .ico {
                color: mat.m2-get-color-from-palette($primary-palette, 500);
            }
        }

        &.success {
            border-color: mat.m2-get-color-from-palette($primary-palette, 500);

            .ico {
                color: mat.m2-get-color-from-palette($primary-palette, 500);
            }
        }

        &.error {
            border-color: mat.m2-get-color-from-palette($primary-palette, redLight);

            .ico {
                color: mat.m2-get-color-from-palette($primary-palette, redLight);
            }
        }

        .close-dialog-btn:before,
        .close-dialog-btn:after {
            background: mat.m2-get-color-from-palette($primary-palette, greyCustom);
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    p {
        @include mat.m2-typography-level($config, 'headline-5');
    }

    .ico {
        @include mat.m2-typography-level($config, 'headline-6');
    }
}
