@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .dialog-header {
        color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
        background: mat.m2-get-color-from-palette($primary-palette, 500);
    }

    .mat-mdc-dialog-container {
        color: mat.m2-get-color-from-palette($primary-palette, blackCustom);

        .mat-mdc-dialog-content.mdc-dialog__content {
            color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
        }
    }
}
