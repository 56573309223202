.status-label {
    display: block;
    position: relative;
    padding: 0 0 0 2rem;

    > span {
        border-radius: 50%;
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 10px;
        height: 10px;
        margin: -0.6rem 0 0;
        overflow: hidden;

        &[class*='-bg']:after {
            box-shadow: 1px 1px 4px 3px $white;
            position: absolute;
            content: '';
            background: transparent;
            top: 0.1rem;
            left: 0.2rem;
            border-radius: 50%;
            width: 0;
            height: 0;
        }
    }
}
