@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .status-label {
        .success-bg {
            background: mat.m2-get-color-from-palette($primary-palette, greenCustom);
        }

        .primary-bg {
            background: mat.m2-get-color-from-palette($primary-palette, 500);
        }

        .accent-bg {
            background: mat.m2-get-color-from-palette($primary-palette, orangeCustom);
        }

        .disabled-bg {
            background: mat.m2-get-color-from-palette($primary-palette, greyCustom);
        }

        .rejected-bg {
            background: mat.m2-get-color-from-palette($primary-palette, redCustom);
        }

        .voided-bg {
            background: mat.m2-get-color-from-palette($primary-palette, yellowCustom);
        }

        .timeout-bg {
            background: mat.m2-get-color-from-palette($primary-palette, purpleCustom);
        }
    }
}
