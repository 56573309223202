.mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
        justify-content: center;
    }

    .mat-horizontal-content-container {
        padding: 0;
    }

    &.mat-stepper-label-position-bottom {
        .mat-step-header {
            &.mat-horizontal-stepper-header:before,
            &.mat-horizontal-stepper-header:after {
                border: 0;
                display: none;
            }

            &[aria-selected='false'] {
                + .mat-stepper-horizontal-line {
                    border-top-color: $green;
                }
            }
        }

        .mat-horizontal-stepper-header {
            padding: 2.4rem 0.8rem;
        }
    }

    .mat-stepper-horizontal-line {
        border-top: 2px solid;
        width: 7rem;
        transform: translateX(-16px);
    }

    .mat-step-icon {
        width: 3.1rem;
        height: 3.1rem;
        position: relative;
        box-shadow: 0 0 0 3px rgba($grey-light, 0.3);

        &.mat-step-icon-selected {
            box-shadow: 0 0 0 3px rgba($blue, 0.3);
        }

        &.mat-step-icon-state-edit {
            box-shadow: 0 0 0 3px rgba($green, 0.3);
        }

        &.success {
            &:before {
                position: absolute;
                content: '';
                top: -0.4rem;
                bottom: -0.4rem;
                left: -0.4rem;
                right: -0.4rem;
                border-radius: 50%;
            }

            &:after {
                position: absolute;
                content: '';
                height: 0.2rem;
                width: 7rem;
                border-radius: 50%;
                top: 12px;
                right: -98px;
                z-index: 1;
            }

            .mat-icon {
                font-family: $font-family-icomoon;

                &:before {
                    content: '\e905';
                }
            }
        }
    }

    .mat-step-text-label {
        text-overflow: initial;
        overflow: visible;
        white-space: normal;
    }

    .mat-step-header-ripple {
        display: none;
    }
}
