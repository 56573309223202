@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .mat-stepper-horizontal {
        background: none;
        color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);

        .mat-horizontal-stepper-wrapper .mat-stepper-horizontal-line {
            border-top-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
        }

        .title {
            color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
        }

        .mat-step-icon {
            color: mat.m2-get-color-from-palette($primary-palette, greyCustom);
            background: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
        }

        .mat-step-icon-selected {
            color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            background: mat.m2-get-color-from-palette($primary-palette, 500);
        }

        .mat-step-icon-state-edit {
            color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            background: mat.m2-get-color-from-palette($primary-palette, greenCustom);
        }

        .mat-step-header:active,
        .mat-step-header:focus,
        .mat-step-header:hover {
            background: none;
        }

        .mat-step-icon {
            &.sucess {
                background: mat.m2-get-color-from-palette($primary-palette, greenCustom);
                color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);

                &:before {
                    background: mat.m2-get-color-from-palette($primary-palette, greenCustom, 0.3);
                }

                &:after {
                    background: mat.m2-get-color-from-palette($primary-palette, greenCustom);
                }
            }
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .mat-stepper-horizontal {
        mat-step-header {
            .mat-step-label,
            .mat-step-label-selected {
                @include mat.m2-typography-level($config, 'headline-5');
            }

            .mat-step-icon {
                @include mat.m2-typography-level($config, 'headline-2');
            }
        }
    }
}
