@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .mat-mdc-unelevated-button.mat-primary {
        background: mat.m2-get-color-from-palette($primary-palette, 500);
        &:hover {
            background: mat.m2-get-color-from-palette($primary-palette, 700);
        }

        &.mat-mdc-button-disabled:hover {
            background: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
        }
    }

    .mat-mdc-button-base.mat-mdc-outlined-button:not(:disabled),
    .mat-mdc-outlined-button.mat-mdc-button-base {
        color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
        border-color: mat.m2-get-color-from-palette($primary-palette, 500);
        background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);

        &:hover {
            background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            border-color: mat.m2-get-color-from-palette($primary-palette, 700);

            .mat-mdc-button-persistent-ripple:before {
                opacity: 0;
            }
        }

        .mat-mdc-focus-indicator {
            background: mat.m2-get-color-from-palette($primary-palette, whiteCustom) !important;
        }
    }

    .mat-mdc-menu-content {
        .mat-mdc-menu-item {
            min-height: 5.2rem;
            border-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom) !important;

            &:hover:not([disabled]) {
                color: mat.m2-get-color-from-palette($primary-palette, 500);
            }

            &[disabled] {
                opacity: 0.8;
            }
        }
    }

    .icon-more-vertical {
        color: mat.m2-get-color-from-palette($primary-palette, greyCustom);
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .btn {
        @include mat.m2-typography-level($config, 'button');
    }

    .mat-mdc-menu-panel .mat-mdc-menu-content button.mat-mdc-menu-item .mdc-list-item__primary-text {
        @include mat.m2-typography-level($config, 'body-1');
    }

    .icon-more-vertical {
        @include mat.m2-typography-level($config, 'body-2');
    }
}
