app-table-layout,
.cloud-mat-table-box {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    overflow: auto;
    max-height: 100%;
}

mat-table.mat-mdc-table {
    border: 1px solid;
    border-top: 0;

    .mat-sort-header-container {
        width: 100%;
        justify-content: space-between;
    }

    .mat-mdc-row,
    .mat-mdc-header-row {
        min-height: 3.6rem;
        border-bottom: 0;
    }

    .mat-mdc-row {
        z-index: 0;
        position: relative;
    }

    .mat-mdc-cell {
        border-right: 1px solid;
        align-self: stretch;

        &:last-child {
            border: 0;
        }
    }

    mat-header-cell.mat-mdc-header-cell,
    mat-cell.mat-mdc-cell {
        padding: 0 1rem;
        text-align: left;
        justify-content: start;
        border-bottom: 0;
        line-height: normal;
    }

    .mat-sort-header-content {
        text-align: left;
    }

    mat-header-cell[class*='-actions'],
    mat-cell[class*='-actions'] {
        text-align: center;
        justify-content: center;
        max-width: 7.8rem;
    }
}
