@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .mat-mdc-paginator {
        --mat-paginator-container-text-color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
        color: var(--mat-paginator-container-text-color);
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .mat-mdc-paginator .mat-mdc-select-value,
    .mat-mdc-paginator-page-size-select .mat-mdc-select-trigger,
    .mat-mdc-paginator {
        @include mat.m2-typography-level($config, 'button');

        --mat-paginator-container-text-size: 1.6rem;
        --mat-paginator-container-text-weight: 400;
        --mat-paginator-container-line-height: 2rem;
        --mat-paginator-container-font: 'Montserrat Regular', sans-serif;
        --mat-paginator-container-letter-spacing: normal;
        --mat-paginator-select-trigger-text-size: 1.6rem;

        font-size: var(--mat-paginator-container-text-size);
        font-weight: var(--mat-paginator-container-text-weight);
        line-height: var(--mat-paginator-container-line-height);
        font-family: var(--mat-paginator-container-font);
        letter-spacing: var(--mat-paginator-container-letter-spacing);
    }
}
