.wrap {
    padding: 0 2rem 0 0.5rem;
    display: block;
    overflow: hidden;
}

button {
    white-space: nowrap;

    &.mdc-button,
    &.mat-mdc-flat-button,
    &.mat-mdc-icon-button,
    &.mat-mdc-stroked-button,
    &.mat-mdc-raised-button {
        border-radius: 4px;
        line-height: 4rem;
    }

    &.mat-mdc-stroked-button {
        line-height: 3.8rem;
    }

    &.mdc-button.mat-mdc-button-base {
        height: 4rem;
    }

    &.mat-mdc-outlined-button {
        .mat-mdc-focus-indicator {
            border-radius: 4px;
        }
    }

    &.has-btn-icon {
        .mdc-button__label {
            display: flex;
            align-items: center;
        }

        .icon {
            background: none;
            font-size: 1.7rem;
            line-height: 1;
            margin: 0 0.5rem 0 0;
            color: $blue;
        }
    }
}

.mat-mdc-menu-panel .mat-mdc-menu-content {
    padding: 0;
    min-width: 21rem;

    .mat-mdc-menu-item:hover:not([disabled]) {
        background: none;
    }
}

.mat-mdc-icon-button .mat-mdc-button-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple:before {
    opacity: 0;
}

.label {
    display: block;
    border-radius: 3px;
    color: $white;

    &.label-lg {
        padding: 0.9rem 0.75rem;
        height: 26px;
        font-size: 0.9rem;
    }

    &.label-md {
        padding: 0.4rem 0.6rem;
        height: 20px;
        font-size: 0.85rem;
    }

    &.label-sm {
        height: 16px;
        font-size: 0.75rem;
    }

    &.label-inline {
        width: auto;
    }

    &.label-warn {
        background: $red-light;
    }

    &.label-accent {
        background: $orange;
    }

    &.label-success {
        background: $green;
    }

    &.label-primary {
        background: $blue;
    }
}

.mat-mdc-menu-panel.mdc-menu-surface.mdc-menu-surface--open.mat-menu-before {
    box-shadow: 0 1.1rem 3rem rgba(0, 0, 0, 0.1);
    border-radius: 0;

    .mat-mdc-menu-item {
        border-bottom: 1px solid;
    }
}

.no-bottom-indent .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

.loader {
    border: 16px solid $white-smoke;
    border-radius: 50%;
    border-top: 16px solid $blue;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -60px;
    z-index: 100;
}

.mat-mdc-row {
    cursor: pointer;
}

.d-f {
    display: flex;
}

// Datepicker styles
.mat-datepicker-content-container {
    .mat-calendar {
        width: auto !important;
        height: auto !important;
        min-width: 29.6rem;
    }

    .mat-calendar-period-button {
        display: none !important;
    }

    .mat-calendar-header {
        padding: 3px 8px 0 8px;
    }

    .mat-calendar-controls {
        margin: 0;
        justify-content: space-between;
    }

    .mat-calendar-spacer {
        display: none;
    }

    mat-year-view {
        position: relative;

        .mat-calendar-body {
            tr:first-child {
                td {
                    position: absolute;
                    left: 50%;
                    top: -2rem;
                    margin: 0 0 0 -2.1rem;
                    padding: 0 !important;
                }
            }
        }
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.has-image-layout {
    position: relative;

    &:after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.icon-cross {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    font-size: 2.4rem;
    color: $white;
    line-height: 1.1;
    text-align: center;

    &:hover {
        cursor: pointer;
    }
}

.notes {
    border: 1px solid $orange;
    color: $orange;
    border-radius: 0.4rem;
    padding: 2rem;
    background: rgba(255, 151, 0, 0.05);
    margin: 0 0 2rem;

    .notes-title {
        margin: 0 0 1.8rem;
        font: 1.8rem / 2.1rem $font-family-semi-bold;
    }

    p {
        margin: 0;
        font: 1.6rem / 2.4rem $font-family-regular;
    }
}

.uppercase-title {
    text-transform: uppercase;
    letter-spacing: -0.05rem;
}

.person-info {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    margin: 0 0 2rem;

    dt {
        color: rgba(118, 118, 118, 1);
        font-size: 1.4rem;
    }

    dd {
        margin: 0 0 0 1rem;
    }
}

.prev {
    font-family: $font-family-medium;
    font-size: 2.6rem;
    line-height: 1.2;
    color: $black;
    margin: 0 0 1.5rem;

    .icon {
        font-size: 2.6rem;
        cursor: pointer;
    }
}

.loading-indicator {
    position: fixed;
    z-index: 999;
    height: 2rem;
    width: 2rem;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
    }
}

.empty-table-box {
    min-height: 17.7rem;
    background: $white;
}

.no-data {
    position: absolute;
    font-size: 1.6rem;
    top: 50%;
    left: 50%;
    margin: -1rem 0 0 -8.8rem;

    .icon-warning {
        font-size: 2.4rem;
        display: block;
        text-align: center;
        margin: 0 0 0.7rem;
        color: $blue;
    }
}

.action-icon {
    display: block;
    width: 2.75rem;
    height: 2.75rem;
    font-size: 2.4rem;
    line-height: 1.2;
    border-radius: 0.4rem;
    text-align: center;
    cursor: pointer;
}

.capitalize {
    text-transform: lowercase;

    &::first-letter {
        text-transform: capitalize;
    }
}

.page-illustration {
    position: fixed;
    bottom: 4%;
    right: 8%;
    width: 23%;

    &.contact {
        right: 3%;
        width: 33%;
    }
}

.page-title {
    position: absolute;
    top: -4rem;
    left: -1rem;
    margin: 0;
    display: flex;
    align-items: flex-start;

    span {
        margin: 0 0.5rem 0 0;
    }

    .icon {
        display: inline-block;
        margin: 0 0.5rem 0 0;
        color: $grey;
        font-size: 2.2rem;
    }
}

.fixed-header {
    .main-container {
        padding-top: 6rem;
    }

    >app-header-menu {
        display: none;
    }

    .page-title {
        position: fixed;
        top: 0;
        left: 10rem;
        width: calc(100% - 24.8rem);
        background: $white;
        height: 6rem;
        padding: 2rem 0 0 2.2rem;
        z-index: 3;
    }

    app-header-menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 14.8rem;
        z-index: 3;
        background: $white;
    }
}

.section-heading-line {
    font: 1.6rem/ 1.2 $font-family-semi-bold;
    color: $blue;
    margin: 0 0 2rem;
    overflow: hidden;
    position: relative;
    display: block;
    width: 100%;

    >span {
        background: $white;
        padding: 0 2rem 0 0;
        position: relative;
        z-index: 2;
        display: inline-block;
    }

    &:after {
        position: absolute;
        content: '';
        height: 1px;
        background-color: $grey-light;
        right: 0;
        top: 50%;
        left: 0;
        z-index: 1;
    }
}