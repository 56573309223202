.mat-mdc-tab-links,
.mat-mdc-tab-labels {
    border-bottom: 1px solid;

    .mdc-tab {
        min-width: 16rem;
    }
}

.mat-mdc-tab-header {
    margin: 0 0 1rem;
}
