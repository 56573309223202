@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .square-ico {
        color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
    }

    .icon-plus {
        background: mat.m2-get-color-from-palette($primary-palette, 500);
        color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);

        &:hover {
            background: mat.m2-get-color-from-palette($primary-palette, 700);
        }
    }

    .icon-cross {
        background: mat.m2-get-color-from-palette($primary-palette, redLight);
        color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);

        &:hover {
            background: mat.m2-get-color-from-palette($primary-palette, redCustom);
        }
    }
}
