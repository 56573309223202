.mat-mdc-tooltip-panel {
    .mat-mdc-tooltip {
        border-radius: 16px;
        position: relative;
        overflow: visible;
        padding: 1.2rem 1.6rem;
        box-shadow: 0.4rem 0.4rem 3rem 0 rgba(0, 0, 0, 0.25);

        .mdc-tooltip__surface {
            background: none;
            color: $black;
            font-size: 1.4rem;
        }

        &:after {
            width: 0;
            height: 0;
            border-style: solid;
            position: absolute;
            content: '';
            border-color: transparent;
        }
    }

    &[class*='above'] {
        .mat-mdc-tooltip:after {
            border-width: 1rem 1rem 0 0;
            border-color: $white transparent transparent transparent;
            left: 50%;
            margin: 0 0 0 -0.2rem;
            bottom: -0.9rem;
        }
    }

    &[class*='right'] {
        .mat-mdc-tooltip:after {
            border-width: 1.2rem 1.2rem 0 0;
            border-color: $white transparent transparent transparent;
            left: 50%;
            margin: 0 0 0 -0.6rem;
            bottom: -1.1rem;
        }
    }

    &[class*='left'] {
        .mat-mdc-tooltip:after {
            border-width: 1.2rem 1.2rem 0 0;
            border-color: $blue transparent transparent transparent;
            right: -1rem;
            margin: -0.6rem 0 0;
            top: 50%;
        }
    }

    &[class*='below'] {
        .mat-mdc-tooltip:after {
            border-width: 1.2rem 0 0 1.2rem;
            border-color: transparent transparent transparent $blue;
            left: 50%;
            margin: 0 0 0 -0.6rem;
            top: -1.1rem;
        }
    }
}
