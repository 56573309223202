@font-face {
    font-family: 'Montserrat Regular';
    src:
        url('../../assets/fonts/montserrat/montserrat-regular.woff2') format('woff2'),
        url('../../assets/fonts/montserrat/montserrat-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat SemiBold';
    src:
        url('../../assets/fonts/montserrat/montserrat-semiBold.woff2') format('woff2'),
        url('../../assets/fonts/montserrat/montserrat-semiBold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Regular Italic';
    src:
        url('../../assets/fonts/montserrat/montserrat-regular-italic.woff2') format('woff2'),
        url('../../assets/fonts/montserrat/montserrat-regular-italic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat Medium';
    src:
        url('../../assets/fonts/montserrat/montserrat-medium.woff2') format('woff2'),
        url('../../assets/fonts/montserrat/montserrat-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'icomoon';
    src: url('../../assets/fonts/icomoon/icomoon.woff?y18f1y') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-more:before {
    content: '\e91e';
}

.icon-cloud:before {
    content: '\e90f';
}

.icon-double-arrow-right:before {
    content: '\e928';
}

.icon-path:before {
    content: '\e929';
}

.icon-list:before {
    content: '\e927';
}

.icon-union:before {
    content: '\e926';
}

.icon-folder:before {
    content: '\e925';
}

.icon-vector:before {
    content: '\e924';
}

.icon-dashboard:before {
    content: '\e923';
}

.icon-warning:before {
    content: '\e921';
}

.icon-cross-circle:before {
    content: '\e922';
}

.icon-arrow-down1:before {
    content: '\e901';
}

.icon-arrow-left:before {
    content: '\e902';
}

.icon-blind:before {
    content: '\e903';
}

.icon-building:before {
    content: '\e904';
}

.icon-check:before {
    content: '\e905';
}

.icon-check-circle:before {
    content: '\e906';
}

.icon-clock:before {
    content: '\e907';
}

.icon-cog:before {
    content: '\e908';
}

.icon-collaboration:before {
    content: '\e909';
}

.icon-copy:before {
    content: '\e90a';
}

.icon-cross:before {
    content: '\e90b';
}

.icon-cubes:before {
    content: '\e90c';
}

.icon-dealer:before {
    content: '\e90d';
}

.icon-delete:before {
    content: '\e90e';
}

.icon-download-cloud:before {
    content: '\e910';
}

.icon-eye:before {
    content: '\e911';
}

.icon-Info:before {
    content: '\e912';
}

.icon-key:before {
    content: '\e913';
}

.icon-log-out:before {
    content: '\e914';
}

.icon-more-horizontal:before {
    content: '\e915';
}

.icon-more-vertical:before {
    content: '\e916';
}

.icon-notific:before {
    content: '\e917';
}

.icon-pencil:before {
    content: '\e918';
}

.icon-plus:before {
    content: '\e919';
}

.icon-profile:before {
    content: '\e91a';
}

.icon-refresh:before {
    content: '\e91b';
}

.icon-search:before {
    content: '\e91c';
}

.icon-wrench:before {
    content: '\e91d';
}

.icon-arrow-down:before {
    content: '\e900';
}
