.mat-mdc-paginator {
    button {
        &.mat-mdc-icon-button.mat-mdc-button-base {
            width: 4.4rem;
            height: 4.4rem;
            padding: 0.5rem;
            margin: 0 0 0 3rem;

            svg {
                width: 3.2rem;
                height: 3.2rem;
            }
        }
    }

    .mat-mdc-paginator-range-label {
        margin: 0 0 0 0.2rem;
    }
}
