@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    mat-option.mat-mdc-option {
        border-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
    }

    .mdc-text-field {
        &--filled:not(.mdc-text-field--disabled),
        &--outlined:not(.mdc-text-field--disabled) {
            .mdc-text-field__input {
                color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
            }

            .mdc-notched-filled,
            .mdc-notched-outline {
                &__trailing,
                &__leading,
                &__notch {
                    border-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
                }
            }
        }

        &:hover:not(.mdc-text-field--disabled),
        &--focused:not(.mdc-text-field--disabled) {
            .mdc-notched-filled,
            .mdc-notched-outline {
                &__trailing,
                &__leading,
                &__notch {
                    border-width: 1px;
                    border-color: mat.m2-get-color-from-palette($primary-palette, 500) !important;
                }
            }
        }

        &--invalid:hover:not(.mdc-text-field--disabled),
        &--invalid:not(.mdc-text-field--disabled) {
            .mdc-notched-filled,
            .mdc-notched-outline {
                &__trailing,
                &__leading,
                &__notch {
                    border-color: mat.m2-get-color-from-palette($primary-palette, redLight) !important;
                }
            }
        }

        &--filled.mdc-text-field--disabled,
        &--outlined.mdc-text-field--disabled {
            .mat-mdc-input-element {
                color: mat.m2-get-color-from-palette($primary-palette, greyAverageCustom);
                position: relative;
                z-index: 2;
            }

            .mdc-notched-filled,
            .mdc-notched-outline {
                &__trailing,
                &__leading,
                &__notch {
                    background: mat.m2-get-color-from-palette($primary-palette, greyLightestCustom);
                }
            }
        }
    }

    .mat-mdc-select-value {
        color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
    }

    .mdc-text-field--disabled {
        .mat-mdc-select-value {
            color: mat.m2-get-color-from-palette($primary-palette, greyAverageCustom);
        }
    }

    .mat-mdc-option:hover:not(.mdc-list-item--disabled),
    .mat-mdc-option:focus:not(.mdc-list-item--disabled),
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
        background: none;
        color: mat.m2-get-color-from-palette($primary-palette, 500);
    }

    mat-checkbox.mat-mdc-checkbox.mat-accent .mdc-form-field {
        .mdc-checkbox,
        .mdc-checkbox:not(:disabled):active {
            .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
                border-color: mat.m2-get-color-from-palette($primary-palette, greyCustom);
                background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
            }
        }
    }

    .mdc-checkbox--disabled {
        .mdc-checkbox__background {
            border-color: transparent !important;
            background: rgba(44, 108, 147, 0.5) !important;
        }

        .mdc-checkbox__native-control:not(:checked) ~ .mdc-checkbox__background {
            border-color: rgba(118, 118, 118, 0.5) !important;
            background: rgba(118, 118, 118, 0.4) !important;
        }
    }

    .mat-mdc-header-cell mat-checkbox.mat-mdc-checkbox.mat-accent .mdc-form-field .mdc-checkbox {
        .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
        .mdc-checkbox__native-control:checked:enabled ~ .mdc-checkbox__background {
            border-color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
        }

        .mdc-checkbox__mixedmark {
            width: 76%;
        }
    }

    mat-radio-button.mat-mdc-radio-button {
        .mdc-radio,
        .mdc-radio:hover {
            .mdc-radio__native-control {
                &:hover + .mdc-radio__background,
                & + .mdc-radio__background {
                    background: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
                }

                &:enabled:not(:checked) + .mdc-radio__background,
                &:disabled:not(:checked) + .mdc-radio__background {
                    .mdc-radio__inner-circle,
                    .mdc-radio__outer-circle {
                        border-color: mat.m2-get-color-from-palette($primary-palette, blackAverageCustom);
                    }
                }

                &:checked + .mdc-radio__background {
                    .mdc-radio__inner-circle,
                    .mdc-radio__outer-circle {
                        border-color: mat.m2-get-color-from-palette($primary-palette, 500);
                    }
                }

                &:disabled + .mdc-radio__background {
                    .mdc-radio__inner-circle,
                    .mdc-radio__outer-circle {
                        opacity: 1;
                        border-color: mat.m2-get-color-from-palette($primary-palette, 200);
                    }
                }

                &:disabled:not(:checked) + .mdc-radio__background {
                    background: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
                }
            }
        }
    }

    mat-label {
        color: mat.m2-get-color-from-palette($primary-palette, greyCustom);
    }

    .text-area.mat-mdc-form-field {
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            background-color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .mat-mdc-checkbox-label,
    .mat-mdc-radio-label,
    .mat-mdc-radio-button {
        @include mat.m2-typography-level($config, 'headline-5');
    }

    mat-label {
        @include mat.m2-typography-level($config, 'headline-2');
    }
}
