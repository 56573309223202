.ico {
    position: relative;
    display: inline-block;
}

.square-ico {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 4px;
    &:before {
        font-size: 2.8rem;
    }
}
