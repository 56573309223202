@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');
    .mat-mdc-tab-list,
    .mat-mdc-tab-group {
        .mat-mdc-tab-links,
        .mat-mdc-tab-labels {
            border-color: mat.m2-get-color-from-palette($primary-palette, greyLightCustom);
        }

        .mat-mdc-tab,
        .mat-mdc-tab-link {
            &:hover {
                .mdc-tab__ripple:before {
                    background: none;
                }
            }

            .mdc-tab__text-label {
                color: mat.m2-get-color-from-palette($primary-palette, greyCustom);
            }

            &:not(.mat-mdc-tab-disabled).active,
            &:not(.mat-mdc-tab-disabled).mdc-tab--active {
                .mat-ripple.mat-mdc-tab-ripple {
                    display: none;
                }

                .mdc-tab-indicator__content--underline {
                    border-color: mat.m2-get-color-from-palette($primary-palette, orangeCustom);
                    opacity: 1;
                }

                .mdc-tab__text-label {
                    color: mat.m2-get-color-from-palette($primary-palette, blackCustom);
                }
            }
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);

    .mat-mdc-tab-group {
        @include mat.m2-typography-level($config, 'body-1');
    }

    .mdc-tab__text-label {
        @include mat.m2-typography-level($config, 'button');
    }
}
