// Typography
//
// Base font styles for headings and links.

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
    font-family: $font-family-regular;
    font-weight: normal;
    margin: 0 0 0.5em;
}

h1,
.h1 {
    font-size: 1.8rem;
}

h2,
.h2 {
    font-size: 1.6rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.4rem;
}

h5,
.h5 {
    font-size: 1.3rem;
}

h6,
.h6 {
    font-size: 1.2rem;
}

p {
    margin: 0 0 1em;
}

a {
    color: $blue;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}
