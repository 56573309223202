mat-snack-bar-container.mat-mdc-snack-bar-container {
    border: 2px solid;
    padding: 2rem 3.5rem 2rem 2rem;
    min-width: 41.7rem;
    display: block;
    border-radius: 6px;
    margin: 3rem;
    .mdc-snackbar__surface {
        background: none;
        box-shadow: none;
    }

    .mat-mdc-snack-bar-label {
        padding: 0;
    }

    &.width-70 {
        min-width: 70rem;
    }
}
