form,
fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
}

input,
input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
textarea {
    font-family: inherit;
    font-size: 1.6rem;
    line-height: 1.3;
    -webkit-appearance: none;
    outline: none;

    &::placeholder,
    &::-webkit-input-placeholder {
        color: $grey !important;
        font-family: $font-family-regular-italic;
        opacity: 1;
    }
}

select {
    -webkit-border-radius: 0;
}

.select-with-shadow-scroll-overlay {
    scrollbar-color: $blue;

    &.mdc-menu-surface.mat-mdc-select-panel.mdc-menu-surface--open {
        box-shadow: 0 1.1rem 3rem 0 rgba(0, 0, 0, 0.1);
        padding-top: 0.8rem;
        max-height: 20rem;
    }

    mat-option.mat-mdc-option {
        padding-left: 3.4rem;
        font-size: 1.4rem;

        &:first-child {
            .mdc-list-item__primary-text {
                color: $grey;

                &:hover {
                    color: $blue;
                }
            }
        }

        &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
            background: $blue-zinc;

            .mdc-list-item__primary-text {
                color: $black;
            }
        }

        &:hover:not(.mdc-list-item--disabled) {
            color: $blue;

            .mdc-list-item__primary-text {
                color: $blue;
            }
        }
    }
}

.select-with-trigger-overlay {
    &.mdc-menu-surface.mat-mdc-select-panel.mdc-menu-surface--open {
        max-height: 32rem;
        box-shadow: 0 1.1rem 3rem 0 rgba(0, 0, 0, 0.1);
        padding-top: 0.8rem;
    }

    mat-option.mat-mdc-option {
        border-color: transparent;
        min-height: 2.8rem;
        padding-left: 3.1rem;

        &:first-child {
            border-top: 1px solid $grey-light;
        }

        &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
            background: transparent;
        }

        &.selected-option:not(.mdc-list-item--disabled) {
            background: $blue-zinc !important;
        }

        &:hover:not(.mdc-list-item--disabled) {
            color: $blue;

            strong,
            span {
                color: $blue;
            }
        }

        @media (max-width: 1399px) {
            font-size: 1.4rem;
        }
    }

    .mdc-list-item__primary-text {
        color: $grey;
        display: flex;
        gap: 6rem;

        @media (max-width: 1399px) {
            gap: 5rem;
        }

        strong,
        span {
            color: $black;
        }

        strong {
            min-width: 7.6rem;
        }
    }
}

textarea {
    vertical-align: top;
}

button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
    @include animate(all);
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
}

.mat-mdc-button,
.mat-mdc-button:hover,
.mat-mdc-button-base:hover,
.mat-mdc-button-base {
    .mdc-button__label {
        font-size: 1.6rem;
        letter-spacing: normal;
    }

    .mat-mdc-button-focus-overlay {
        display: none;
    }
}

mat-label {
    margin: 0 0 0.2rem;
    font-size: 1.2rem;
    line-height: 1;
    display: block;
}

.mat-mdc-form-field {
    .mdc-text-field--outlined {
        &:not(.mdc-text-field--disabled) {
            .mat-mdc-notch-piece {
                border-width: 1px;
                border-color: #dedede;
            }
        }
    }

    .mdc-text-field--outlined {
        &:not(.mdc-text-field--disabled) {
            &.mdc-text-field--focused {
                .mdc-notched-outline {
                    .mat-mdc-notch-piece {
                        border-width: 1px;
                    }
                }
            }
        }
    }

    .mat-mdc-form-field-error-wrapper {
        padding: 0;
        top: -0.2rem;
        left: -0.2rem;
        line-height: 1;
    }

    .mdc-icon-button {
        z-index: 2;
        top: 0;
        left: 0;
    }

    input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
        appearance: none;
    }

    .mat-mdc-form-field-icon-suffix {
        .mat-icon {
            background: $white;
        }

        .mdc-icon-button {
            left: auto;
            right: 0;
        }
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--filled,
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-infix {
            height: 3.6rem;
            min-height: 3.6rem;
        }

        .mat-mdc-form-field-infix {
            padding: 0.7rem 0 0.3rem;
        }
    }

    .mat-mdc-select-arrow-wrapper {
        margin-top: -0.4rem;
    }

    .mat-mdc-select-arrow {
        border: solid $grey;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        width: 0;
        height: 0;

        svg {
            display: none;
        }
    }

    &.search-holder {
        height: 3.6rem;
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    width: 3.6rem;
    height: 3.6rem;
    padding: 0.2rem;

    &.copy-btn {
        .mat-mdc-button-touch-target {
            display: none;
        }
    }

    .mat-icon {
        color: $grey;
    }
}

.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    .mat-pseudo-checkbox {
        display: none;
    }
}

.mat-mdc-select-value {
    position: relative;
    z-index: 2;
}

.mat-mdc-option {
    border-bottom: 1px solid;
}

.mat-mdc-checkbox.mat-accent .mdc-form-field,
.mat-mdc-radio-button.mat-accent .mdc-form-field {
    label {
        cursor: pointer;
    }

    .mat-mdc-radio-touch-target {
        display: none;
    }

    .mdc-radio__background {
        &:before {
            display: none;
        }
    }

    .mdc-checkbox,
    .mdc-radio {
        padding: 0;
        width: 2.2rem;
        height: 2.2rem;
        flex: 0 0 2.2rem;
        margin: 0 0.4rem 0 0;

        .mdc-checkbox__native-control,
        .mdc-radio__native-control {
            width: 2.2rem;
            height: 2.2rem;
            position: absolute;
            z-index: 10;

            ~ .mdc-radio__background,
            ~ .mdc-checkbox__background {
                width: 2.2rem;
                height: 2.2rem;
                top: 0;
                left: 0;
                position: absolute;
            }

            ~ .mdc-radio__background {
                border-radius: 50%;
            }

            ~ .mdc-radio__ripple,
            ~ .mat-ripple.mat-radio-ripple,
            ~ .mat-ripple.mat-mdc-checkbox-ripple,
            ~ .mdc-checkbox__ripple {
                opacity: 0;
                background: none;
            }
        }

        .mdc-radio__native-control:checked ~ .mdc-radio__background {
            .mdc-radio__inner-circle {
                border-width: 1.1rem;
            }
        }
    }
}

.search-holder {
    .mdc-text-field {
        .mat-mdc-input-element {
            font-family: $font-family-regular-italic;
            letter-spacing: normal;
        }
    }
}

.mat-mdc-form-field.with-label-inside {
    &.mat-focused,
    &:active,
    &:hover {
        .mat-mdc-form-field-focus-overlay {
            opacity: 0;
        }

        .mat-mdc-text-field-wrapper {
            border-color: $blue;
        }
    }

    .mdc-text-field {
        padding: 0 1.6rem 0 1rem;
    }

    .mat-mdc-form-field-infix {
        display: flex;
        width: auto;
        margin: 0;
        align-items: center;
        margin: 0;

        .mat-mdc-select-value {
            min-width: 11rem;
        }

        .mdc-floating-label {
            position: static !important;
            transform: none;
            overflow: visible;

            mat-label {
                font-size: 1.6rem;
                color: $black;
                margin: 0 0.5rem 0 0;
            }
        }
    }

    &.mat-form-field-disabled {
        .mat-mdc-form-field-infix {
            .mdc-floating-label {
                mat-label {
                    color: $grey;
                }
            }
        }
    }

    &.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
        margin: 0;
        transform: translateY(-0.3rem);
    }

    .mdc-text-field--filled {
        background-color: $white;
    }

    .mdc-line-ripple:after,
    .mdc-line-ripple:before {
        display: none !important;
    }

    .mat-mdc-text-field-wrapper {
        border: 1px solid $grey-light;
        border-radius: 4px;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--filled {
        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-infix {
            height: 3.4rem;
            min-height: 3.4rem;
        }
    }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
