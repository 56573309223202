@use 'sass:map';
@use '@angular/material' as mat;

mat-dialog-container {
    &.mat-mdc-dialog-container {
        width: 50rem;
        height: auto;
        border-radius: 4px;
        background: $white;
        box-shadow: 0 0 40px 1px rgba(0, 0, 0, 0.15);

        .dialog-header {
            height: 4.4rem;
            position: relative;
            padding: 1.2rem 3.5rem 1.2rem 2rem;
            margin: -2rem -2rem 2rem;

            .mat-mdc-dialog-title {
                margin: 0;
                font-size: 1.4rem;
                line-height: 2rem;
                color: $white;
                padding: 0;

                &:before {
                    display: none;
                }
            }
        }

        .mat-mdc-dialog-surface {
            box-shadow: none;
            padding: 2rem;
        }

        .mat-mdc-dialog-content {
            margin: 0 -2rem;
            padding: 0 2rem;
        }

        .mat-mdc-dialog-actions {
            margin-bottom: 0;
            justify-content: flex-end;
            min-height: auto;
            gap: 2rem;
            padding: 0.8rem 0 0;

            button {
                &.mat-mdc-button-base,
                &.mat-mdc-button,
                &.mat-mdc-flat-button,
                &.mat-mdc-icon-button,
                &.mat-mdc-stroked-button,
                &.mat-mdc-raised-button {
                    padding: 0 2rem;
                    margin-left: 0;
                }
            }
        }

        .alert-dialog {
            .mat-mdc-dialog-actions {
                justify-content: flex-end;
            }
        }

        .subtitle {
            font-family: $font-family-semi-bold;
            display: block;
            margin: 0 1rem 0.5rem 0;
        }
    }
}

.average-width {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 56rem;
    }
}

.width-66 {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 66rem;
    }
}

.width-53 {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 53rem;
    }
}

.registration-confirmation {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 52rem;
    }
}

.large-width {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 60rem;

        .mat-mdc-dialog-surface {
            padding: 4rem 4rem 2.6rem;
        }

        .dialog-header {
            margin: -4rem -4rem 2rem;
        }

        .mat-mdc-dialog-content {
            margin: 0 -4rem;
            padding: 0 4rem;
        }

        .mat-mdc-dialog-actions {
            padding: 3rem 0 0;
        }
    }
}

.full-height {
    height: 96%;

    mat-dialog-container.mat-mdc-dialog-container {
        width: 140rem;
    }
}

.large-dialog-container {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 140rem;
    }
}

.large-dialog-container-w104 {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 104rem;
    }
}

.full-height,
.large-dialog-container,
.large-dialog-container-w104 {
    mat-dialog-container.mat-mdc-dialog-container {
        .mat-mdc-dialog-content {
            max-height: 100%;
            overflow: hidden;
        }
    }
}

.close-dialog-btn {
    font-size: 0;
    position: absolute;
    top: 1.2rem;
    right: 1.4rem;
    width: 1.4rem;
    height: 1.4rem;
    border: none;
    background: none;

    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 1rem;
        left: 0;
        width: 1.4rem;
        height: 0.2rem;
        background: $white;
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}

.cdk-overlay-pane {
    &.edit-dialog {
        min-width: 65vw;
        min-height: 65vh;

        mat-dialog-container {
            &.mat-mdc-dialog-container {
                width: 100%;
            }
        }
    }

    &.generate-license-dialog {
        mat-dialog-container {
            &.mat-mdc-dialog-container {
                width: 39rem;
            }
        }
    }

    &.confirmation-dialog,
    &.alert-dialog {
        mat-dialog-container {
            &.mat-mdc-dialog-container {
                width: 53rem;
            }
        }
    }

    &.updates-wizard-dialog {
        mat-dialog-container {
            &.mat-mdc-dialog-container {
                width: 102rem;
            }
        }
    }

    &.confirmation-dialog-sm {
        mat-dialog-container {
            &.mat-mdc-dialog-container {
                width: 40rem;
            }
        }
    }

    &.updates-details-dialog {
        min-width: 80vw;
        min-height: 70vh;

        .mat-mdc-dialog-container {
            width: 100%;

            .mat-mdc-card-title-group {
                justify-content: flex-end;
                flex-direction: row-reverse;

                .mat-mdc-card-subtitle {
                    margin-bottom: 0;
                }

                .module-logo {
                    margin-right: 1rem;

                    img.mat-mdc-card-sm-image {
                        width: 50px;
                        height: 50px;
                        border: 3px solid #ccc;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    &.client-details-view-dialog {
        &.mat-mdc-dialog-panel {
            min-width: 80vw;
            min-height: 70vh;

            .mat-mdc-dialog-container {
                width: 100%;
            }
        }
    }

    &.cdk-overlay-pane {
        .mat-mdc-dialog-content {
            min-height: 0;
        }
    }

    &.warning-dialog {
        &:not(.confirmation-dialog) {
            .mat-mdc-dialog-content {
                min-height: 40vh;
            }
        }
    }

    .bolder-version {
        font-family: $font-family-medium;
    }
}

.create-contact-confirmation {
    mat-dialog-container.mat-mdc-dialog-container {
        width: 53rem;

        .confirm-popup--msg p {
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin: 0 0 2rem;
        }
    }
}
