@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    .main-menu.mat-drawer {
        background: none;

        .mat-drawer-inner-container {
            background: mat.m2-get-color-from-palette($primary-palette, 500);
        }

        .mat-mdc-list-base .mat-mdc-list-item {
            color: mat.m2-get-color-from-palette($primary-palette, whiteCustom);
        }

        .mat-mdc-list-item:hover,
        .mat-mdc-list-item.selected {
            background: mat.m2-get-color-from-palette($primary-palette, 700);
            &:after {
                background: mat.m2-get-color-from-palette($primary-palette, 700);
            }

            .mdc-list-item__content {
                &:before {
                    background: mat.m2-get-color-from-palette($primary-palette, orangeCustom);
                }
            }
        }
    }
}

@mixin typography($config-or-theme) {
    $config: mat.m2-get-typography-config($config-or-theme);
    .mdc-list-item__content,
    .mat-mdc-nav-list,
    p {
        @include mat.m2-typography-level($config, 'headline-5');
    }
}
